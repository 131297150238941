
[data-v-75f0fd1a] .modal-body {
  overflow: hidden !important;
}
[data-v-75f0fd1a] .modal-body #mermaid {
  width: 100%;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] {
  width: 100%;
  height: calc(100dvh - 50px);
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .node.clickable.node-active:not(.disabled) > rect {
  fill: greenyellow;
  stroke: royalblue;
  stroke-width: 3px;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .node.clickable.node-active-sub:not(.disabled) > rect {
  fill: lightgreen;
  stroke: aqua;
  stroke-width: 1px;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .node.clickable.node-active-sub:not(.disabled).double > rect {
  fill: aqua;
  stroke: royalblue;
  stroke-width: 3px;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .node.clickable.disabled {
  opacity: 0.3 !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.edge-path-active:not(.disabled) > .path {
  stroke: deeppink !important;
  stroke-width: 3px;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.edge-path-active:not(.disabled) > defs > marker {
  stroke: deeppink;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.edge-path-active:not(.disabled) > defs > marker > path {
  fill: deeppink !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.edge-path-active:not(.disabled).double > .path {
  stroke: royalblue !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.edge-path-active:not(.disabled).double > defs > marker {
  stroke: royalblue;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.edge-path-active:not(.disabled).double > defs > marker > path {
  fill: royalblue !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgePath.disabled {
  opacity: 0.3 !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgeLabel {
  background-color: inherit !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgeLabel rect {
  opacity: 0 !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgeLabel .edgeLabel {
  cursor: pointer;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgeLabel.edge-label-active:not(.disabled) {
  background-color: #eee !important;
  color: deeppink !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgeLabel.edge-label-active:not(.disabled).double {
  color: royalblue !important;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] .edgeLabel.disabled {
  color: grey !important;
  cursor: move;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] g.label > g > foreignObject > div {
  display: flex !important;
  justify-content: space-around;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] g.label > g > foreignObject > div > span > span {
  display: block;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] g.label > g > foreignObject > div > span > span > i.fa-exclamation-circle {
  color: red;
  display: inline;
  margin-left: 5px;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] g.label > g > foreignObject > div > .search {
  font-size: 20px;
}
[data-v-75f0fd1a] .modal-body #mermaid > svg[id^="mermaid-"] g.label > g > foreignObject > div > .search:hover {
  color: royalblue;
}
[data-v-75f0fd1a] .modal-body .pinch-zoom-wrapper {
  background-color: inherit !important;
}
[data-v-75f0fd1a] .modal-body .pinch-zoom-wrapper > .pz-zoom-button.pz-zoom-control-position-bottom {
  display: none;
}
[data-v-75f0fd1a] .modal-body #task-info {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  padding: 5px;
  font-size: 16px;
  font-weight: 700;
}
